import {createGlobalStyle} from 'styled-components';

export const GlobalStyle = createGlobalStyle`



html {
  /* font-size: 62.5%; */
  /** rem 코딩 (1rem = 10px) */
}


`;
