import styled from 'styled-components';

const NotYetContents = () => {
  return (
    <Container>
      <h1>정산 관리</h1>
    </Container>
  );
};

export default NotYetContents;

const Container = styled.div`
  font-size: 3rem;
`;
